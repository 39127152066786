import React from 'react';
import {useParams} from 'react-router-dom';

const Home = () => {
    const params = useParams();

    const inputString = params.id;
    const targetWord = "S9G8A1G0";

    if (inputString.includes(targetWord)) {
        window.location.href = "https://docs.google.com/presentation/d/1El8Ssl-cwYpIkPa_uExP-Q059RiorYsk/edit?usp=sharing&ouid=117109550057456519536&rtpof=true&sd=true"
    } else {
        console.log("The string does not contain the target word.");
    }
    
    return (
        <div>
        </div>
    )
}

export default Home